import '../styles/globals.scss';
import 'swiper/swiper.scss';
import 'swiper/components/effect-fade/effect-fade.scss';
import '@easyview/news-style';
import '@/utils/globalWs';
import '@/components/News/pdfh5News.css'
import App from '@/components/App/App';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

Sentry.init({
  dsn: 'https://84965f4b74db4c9b9d8900b1bd6bd5b7@o893270.ingest.sentry.io/5840116',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

export default App;
