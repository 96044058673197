export default `USER AGREEMENT
													
Welcome to the Extramile Community Forum!

1.	TERMS of USE 
This contract (“Contract” or “User Agreement”) applies to the Extramile Community Forum (the “Forum”), operated by Goldhorse Capital Management (HK) Limited (“we”, or “us”, or “ours”), and other services that state that they are offered under this User Agreement (“Services”), including the offsite collection of data for those Services, such as but not limited to our ads and other plugins. Registered users of our Services are “Extramilers” and unregistered users are “Visitors”. This Contract applies to both Extramilers and Visitors. When you register an Extramile account, either by yourself or via authorized personnel of your company, you agree to this User Agreement and this Contract constitutes an agreement between you and us.
As a Visitor or Extramiler, the collection, use and sharing of your personal data is subject to our Privacy Policy (which includes our Cookie Policy and other documents referenced in the Privacy Policy) and updates. 
2.	SERVICES
Services provide includes the following:
a)	The Forum allows eligible Extramilers to post information, to vote in poll, and to feedback, and to share views with other Extramilers or Visitors;
b)	We aim to provide a positive and professional environment to foster exchanges among Extramilers and Visitors; and
c)	We will be connecting you with certain advertisement, offers, or other sponsored content based on the information you submit on the Forum. However, unless we receive a written consent from you, we will not directly or indirectly sell or share information with our commercial business partners that can identify you, such as your name, email addresses, icon, or registration number.

3.	REPRESENTATIONS AND WARRANTIES

3.1	Representations and Warranties by us
We hereby represent and warrant to the you that, as of the first time you use our Service:
a)	we are duly organized, validly incorporated, existing, and registered in accordance with the laws of its country of registration; and
b)	any information which we have provided to you in relation to our licenses, consents, authorizations, approvals, or taxation status are complete and correct, and we will inform you if any of its licenses, consents, authorizations or approvals ceases to be valid or effective (including, without limitation, any revocation of the same by any regulator and/or governmental body).

3.2	Representations and Warranties by you
You hereby represent, warrants and covenants to us that, as of the first time you use our Service:
(i)	you are at least 18 years old or the minimum legal age in your country to independently enter into a contract;
(ii)	you are legally allowed to receiving any aspect of our Service under applicable guidance, laws, regulations, or other industry practice that you may be subjected to;
(iii)	you understand that you are not always required to disclose your true identity or reveal information that could be linked directly to your identity when using the Service, but you must provide us with accurate information when registering for the Service, and agree to provide us with any update to the information as soon as professionally possible;
(iv)	you will not create an account for anyone other than yourself;
(v)	you will not do anything unlawful, post any misleading, false, or fraudulent information or anything that violates other’s intellectual property rights, or act against any guidance, laws, regulations, or industry guidelines that you are subjected to;
(vi)	other than your personal information, you grant us a non-exclusive, royalty-free, transferable, worldwide license to use, distribute, quote, share, modify, publicly display, translate, enhance or create derivative works of any of the information in relation to you using our Services;
(vii)	to the extent necessary to effect the provisions of clause 3.2(vi), you will assign the intellectual property rights with effect from creation to us or our nominee; 
(viii)	you will only use our intellectual property, trademarks, or logo with our prior written consent; 
(ix)	your decision to use part or all of the Service is based on your own judgement and understanding of the circumstances or information that you may receive or conclusion drawn by yourself from accessing the Forum. You also agree that we, while providing the Services, gives absolutely no assurance as to accuracy, expected return, nor makes any recommendation with regards to the merits of or any investment opportunity you come across in the course of receiving the Services; and
(x)	you or your representatives do not have, and will not enter into any commitments with any 3rd party that conflict with your obligations under this Contract or potentially damage our primary business interest.

4.	INDEMNITY
You shall indemnify and keep indemnified and hold us, and any of our directors, affiliates, officers and agents (each an Indemnified Party) harmless in respect of any and all liabilities, losses, costs, claims, damages, actions and related expenses whatsoever and howsoever arising out of the negligence, breach of duty (including statutory duty), willful default, bad faith, fraud or breach of any provision of this Contract by you or your directors, affiliates and officers. This indemnification provision shall survive the termination of this Contract.
5.	OPERATION CONTORL

5.1	Removal of Content
We can, without prior notice, remove any content or information posted or shared by you on the Services if we believe such information violates this Contract, any guidelines, laws, regulations that each party is subjected to, or if required to do so by law.
5.2	Termination of Account
We can refuse to provide all or part of the Service to you immediately without notice if you violate this Contract or any other Contract you or your representatives have separately signed with us, intentionally or unintentionally infringe other people’s right to utilize our Service in a professional manner, or where we are permitted or required to do so by law. 
If you do not feel comfortable to continue using the Services, you can also request to delete your content or your registration with Forum by writing to bdsg@goldhorse.com.sg. We will process your request as reasonably as practicable. However, for the avoidance of doubt, although deleted information may not be visible to other Users, it will be kept by us for a maximum of 7 calendar years, and may be shared to regulators or law enforcement agencies if we are required and are necessary to do so.
6.	MISCELLANEOUS PROVISIONS

6.1 	Relationship between the Parties
For all purposes of this Contract, we shall be a service provider and not an employee, employer, agent, partner or joint venturer of you.
6.2 	No Exclusivity
This Contract does not confer any exclusive rights to you in respect of the Services. 
6.3 	Limitation of Responsibility
We are bounded, within the framework of the execution of its duties and obligations, by a best effort obligation towards you. In this respect, our responsibility is limited to errors committed through, and losses generated by, willful misconduct, recklessness or gross negligence. This Contract does not include any performance obligation. We cannot in particular take responsibility for the fluctuation of the values of the investments, the changes in interest rates or the resulting fluctuations in returns, or any recommendations posted by other Extramilers, even if you suffer any losses related to such recommendations that we should or should not have removed from the Forum. 
6.4 	Amendments to this Agreement
We may modify this Contract, our Privacy Policy and our Cookies Policy from time to time to reflect our the changes to the provision of Services. We will provide you notice about such modification through our Services, or by other means. If you object to any changes, you may close your account. Your continued use of our Services after we publish or send a notice about our changes to these terms means that you are consenting to the updated terms as of their effective date.
6.5	Assignment
We are entitled to assign our rights and obligations resulting from any and all agreements signed and executed with you to another group company with prior written notice to the you before such assignment. You shall notify us of such objection within ten (10) Business Days from receipt of such notice. Without objection, the Client hereby consents to such assignment.
You cannot transfer your rights or obligations under this Contract without our prior written consent.
6.6	Entire Agreement
This Agreement, its appendices, amendments, annex, which could be added to or deleted from this Agreement from time to time, constitute the entire agreement between you and us with respect to the subject matter of this Contract.
6.7	Applicable Law and Place of Jurisdiction
This Agreement and all business and legal relationships between the Parties are exclusively and irrevocably governed by the laws of Hong Kong and the Parties agree to submit to the non-exclusive jurisdiction of the courts of Hong Kong in relation to all matters arising from this Agreement.`;
