import { act, useMemo } from 'react';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';

let store;

const initialState = {
  loginUserInfo: null,
  underlyings: {},
  isFetchingUnderlyings: false,
  issuers: {},
  isFetchingIssuers: false,
  custodians: {},
  isInApp: false,
  isSeller: false,
  institutionInfo: {},
  institutionUserInfo: {},
  isFetchingInstitutionInfo: false,
  individualStatus: -1, // 个人用户状态
  accountType: '', // 用户类型
  newsClassifications: {},
  platformType: {},
  generalInfo: {},
  productsConfig: {},
  isFetchingProductsConfig: false,
  individualUserInfo: {}, // 个人用户信息
  proPlanList: [], // 个人用户订阅列表
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_LOGIN_USER_INFO':
      return {
        ...state,
        loginUserInfo: action.loginUserInfo,
      };
    case 'UPDATE_UNDERLYINGS':
      return {
        ...state,
        underlyings: action.underlyings,
        isFetchingUnderlyings: false,
      };
    case 'IS_FETCHING_UNDERLYINGS':
      return {
        ...state,
        isFetchingUnderlyings: action.isFetchingUnderlyings,
      };
    case 'UPDATE_ISSUERS':
      return {
        ...state,
        issuers: action.issuers,
        custodians: action.custodians,
        isFetchingIssuers: false,
      };
    case 'IS_FETCHING_ISSUERS':
      return {
        ...state,
        isFetchingIssuers: action.isFetchingIssuers,
      };
    case 'IS_IN_APP':
      return {
        ...state,
        isInApp: action.isInApp,
      };
    case 'IS_SELLER':
      return {
        ...state,
        isSeller: action.isSeller,
      };
    case 'IS_FETCHING_INSTITUTION_INFO':
      return {
        ...state,
        isFetchingInstitutionInfo: action.isFetchingInstitutionInfo,
      };
    case 'UPDATE_INSTITUTION_INFO':
      return {
        ...state,
        isFetchingInstitutionInfo: false,
        institutionInfo: action.institutionInfo,
        generalInfo: action.generalInfo,
      };
    case 'UPDATE_INSTITUTION_USER_INFO':
      return {
        ...state,
        isFetchingInstitutionUserInfo: false,
        institutionUserInfo: action.institutionUserInfo,
      };
    case 'UPDATE_ACCOUNT_TYPE':
      return {
        ...state,
        accountType: action.accountType,
      };
    case 'UPDATE_INDIVIDUAL_STATUS':
      return {
        ...state,
        individualStatus: action.status,
      };
    case 'UPDATE_INDIVIDUAL_USERINFO':
      return {
        ...state,
        individualUserInfo: action.individualUserInfo,
      };
    case 'UPDATE_NEWS_CLASSIFICATIONS':
      return {
        ...state,
        newsClassifications: action.classifications,
      };
    case 'UPDATE_PLATFORM_TYPE':
      return {
        ...state,
        platformType: action.platformType,
      };
    case 'UPDATE_PRODUCTS_CONFIG':
      return {
        ...state,
        productsConfig: action.productsConfig,
        isFetchingProductsConfig: false,
      };
    case 'IS_FETCHING_PRODUCTS_CONFIG':
      return {
        ...state,
        isFetchingProductsConfig: action.isFetchingProductsConfig,
      };
    case 'SET_PRO_PLAN_LIST':
      return {
        ...state,
        proPlanList: action.payload.items,
      };
    default:
      return state;
  }
};

function initStore(preloadedState = initialState) {
  return createStore(
    reducer,
    preloadedState,
    composeWithDevTools(applyMiddleware(thunkMiddleware))
  );
}

export const initializeStore = (preloadedState) => {
  let _store = store ?? initStore(preloadedState);

  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  if (preloadedState && store) {
    _store = initStore({
      ...store.getState(),
      ...preloadedState,
    });
    // Reset the current store
    store = undefined;
  }

  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') return _store;
  // Create the store once in the client
  if (!store) store = _store;

  return _store;
};

export function useStore(initialState) {
  const store = useMemo(() => initializeStore(initialState), [initialState]);
  return store;
}
