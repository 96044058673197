import { GoodsTradeModeEnum, GoodsTimeTypeEnum, SubscriptionProgram } from '@/utils/pb';
import i18n from './i18n';

// 交易模式
export const tradeModeEnum = () => {
  return [
    {
      label: i18n.t('product.order.common'),
      value: GoodsTradeModeEnum.GOODS_TRANSACTION_MODE_COMMON,
    },
    {
      label: i18n.t('product.order.tapOn'),
      value: GoodsTradeModeEnum.GOODS_TRANSACTION_MODE_TAP_ON,
    },
    {
      label: i18n.t('product.order.addOn'),
      value: GoodsTradeModeEnum.GOODS_TRANSACTION_MODE_ADD_ON,
    },
  ];
};
// 时间类型
export const timeTypeEnum = () => {
  return [
    {
      label: i18n.t('common.time.minute'),
      value: GoodsTimeTypeEnum.GOODS_LAST_TIME_TYPE_MINUTE,
    },
    {
      label: i18n.t('common.time.hour'),
      value: GoodsTimeTypeEnum.GOODS_LAST_TIME_TYPE_HOUR,
    },
    {
      label: i18n.t('common.time.day'),
      value: GoodsTimeTypeEnum.GOODS_LAST_TIME_TYPE_DAY,
    },
    {
      label: i18n.t('common.time.month'),
      value: GoodsTimeTypeEnum.GOODS_LAST_TIME_TYPE_MONTH,
    },
    {
      label: i18n.t('common.time.year'),
      value: GoodsTimeTypeEnum.GOODS_LAST_TIME_TYPE_YEAR,
    },
    // { label: '已结束', value: GoodsTimeTypeEnum.GOODS_LAST_TIME_TYPE_END }, // 100
  ];
};

export const getIndustryType = () => {
  return [
    {
      value: 'External Asset Manager (EAM)',
      desc: 'EAM',
      id: 1,
    },
    {
      value: 'Family Office',
      desc: 'EAM',
      id: 2,
    },
    {
      value: 'Private Bank',
      desc: 'PWM',
      id: 3,
    },
    {
      value: 'Securities House',
      desc: 'PWM',
      id: 4,
    },
    {
      value: 'Others',
      desc: 'PWM',
      id: 5,
    },
  ];
};
