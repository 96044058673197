import { track } from "@/api/user";
import { isInAndroid } from "@/utils/bridge";

export const listenError = ({ userName = '', accountId= '' }) => {
  window.onerror = function (message, source, lineno, colno, error) {
    const errorMsg = {
      userName,
      accountId,
      ...error,
      userAgent: window.navigator.userAgent,
      pageUrl: window.location.href,
      source: isInAndroid() ? "Android" : "IOS",
    };
    const params = {
      messageType: 9,
      triggerTime: Date.now(),
      frontErrorMessage: {
        errorMsg: JSON.stringify(errorMsg),
      },
    };
    track(params);
  };
};
