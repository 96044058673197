import { Component } from 'react';
import { connect } from 'react-redux';
import {
  updateUnderlyings,
  updateIssuers,
  updateNewsClassifications,
  updateProductsConfig,
  updateInstitutionUserInfo,
  updateBuyerInstitutionInfo,
  updateIndividualUserInfo,
  updateIndividualStatus,
} from '@/store/action';
import { getIsSeller, getIsInApp } from '@/utils/config';
import { getUserInfo } from '@/api/user';
import { ACCOUNT_TYPE, parseAccountId } from '@/utils/helper';
import request from '@/utils/request';
import { isReadCommunityProtocol } from '@/api/community';
import { appSocialProtocolDialog } from '@/utils/bridge';

class AppPage extends Component {
  state = {
    isFetchingUserInfo: !getIsInApp(),
    isInitEncrypt: true,
  };
  async componentDidMount() {
    await this.init();
    if (!getIsInApp()) {
      await this.getUserInfo();
      this.initData()
    }
  }

  async getUserInfo() {
    let info = null;
    try {
      info = await getUserInfo();
    } finally {
      this.setState({
        isFetchingUserInfo: false,
      });
    }
    if (info?.accountId) {
      this.props.dispatch({
        type: 'UPDATE_LOGIN_USER_INFO',
        loginUserInfo: info,
      });
    } else {
      this.props.dispatch({
        type: 'UPDATE_LOGIN_USER_INFO',
        loginUserInfo: null,
      });
    }
  }

  // 社区协议弹框
  async getProtocolStatus() {
    const res = await isReadCommunityProtocol();
    const isRead = res?.isRead;
    if (!isRead) {
      const content = require('utils/text/community-protocol').default;
      appSocialProtocolDialog({
        content,
      });
    }
  }

  async init() {
    try {
      await request.initEncrypt();
    } finally {
      this.setState({
        isInitEncrypt: false,
      });
    }
    if (!this.props.loginUserInfo) return;
    this.initData();
  }

  initData() {
    this.initCommon();
    const { accountType } = parseAccountId(this.props.loginUserInfo?.accountId);
    if (accountType === ACCOUNT_TYPE.INDIVIDUAL) {
      this.initIndividualUser();
    }
    if (accountType === ACCOUNT_TYPE.EAM) {
      this.initInstitutionUser();
    }
  }

  // 卖家端 - 分销用户
  initSeller() {}

  // 所有用户类型都会掉的接口
  initCommon() {
    this.props.dispatch(
      updateNewsClassifications({
        languageType: this.props.loginUserInfo.language,
      })
    );
    this.props.dispatch(
      updateProductsConfig(this.props.isFetchingProductsConfig)
    );
    this.props.dispatch(updateUnderlyings(this.props.isFetchingUnderlyings));
    this.props.dispatch(updateIssuers(this.props.isFetchingIssuers));
    this.getProtocolStatus();
  }

  // 机构用户掉的接口
  initInstitutionUser() {
    this.props.dispatch(
      updateBuyerInstitutionInfo(this.props.isFetchingInstitutionInfo)
    );
    this.props.dispatch(
      updateInstitutionUserInfo(
        this.props.isFetchingInstitutionUserInfo,
        this.props.loginUserInfo?.accountId
      )
    );
  }

  // 个人用户掉的接口
  initIndividualUser() {
    this.props.dispatch(
      updateIndividualUserInfo({
        accountId: this.props.loginUserInfo?.accountId,
      })
    );
    this.props.dispatch(updateIndividualStatus());
  }

  render() {
    if (this.state.isInitEncrypt) return null;
    if (this.state.isFetchingUserInfo) return null;
    return this.props.children;
  }
}

const mapStateToProps = (state) => {
  return {
    isFetchingUnderlyings: state.isFetchingUnderlyings,
    isFetchingProductsConfig: state.isFetchingProductsConfig,
    isFetchingIssuers: state.isFetchingIssuers,
    isFetchingInstitutionInfo: state.isFetchingInstitutionInfo,
    loginUserInfo: state.loginUserInfo,
  };
};

export default connect(mapStateToProps)(AppPage);
