import RealTime from './ws';
import { isClient } from './core';

if (isClient()) {
  // const devUrl = 'http://172.16.50.100'; // 开发
  const devUrl = 'http://172.16.50.203'; // 测试
  // const devUrl = 'https://u.extramile.easyview.xyz:8443';
  // const devUrl = 'https://u.distribution.easyview.xyz:8443';

  const isDevelopment = process.env.NODE_ENV === 'development';
  const httpBase = isDevelopment ? devUrl : location.origin;
  const wsUrl = httpBase.replace(/^http/, 'ws') + '/api/goldhorse/websocket';
  window.$ws = new RealTime({ url: wsUrl });
}
