import request from '@/utils/request';

// 新增话题
export function addTopic(params = {}) {
  const req = request.create('DiscussionAddReq', params);
  return request({
    url: '/community/discussion/add',
    requestBody: req,
    responseType: 'DiscussionAddRes',
  });
}

// 查询置顶话题
export function getTopTopic(params = {}) {
  const req = request.create('DiscussionSysQueryReq', params);
  return request({
    url: '/community/discussion/querySysTopDiscussion',
    requestBody: req,
    responseType: 'DiscussionSysQueryRes',
  });
}

// 话题列表
export function getTopicList(params = {}) {
  const req = request.create('QueryDiscussionPageReq', params);
  return request({
    url: '/community/discussion/queryDiscussionPage',
    requestBody: req,
    responseType: 'QueryDiscussionPageRes',
  });
}

// 标签列表
export function getTagsList(params = {}) {
  const req = request.create('DiscussionTagQueryReq', params);
  return request({
    url: '/community/tag/querySysTagList',
    requestBody: req,
    responseType: 'DiscussionTagQueryRes',
  });
}

// 话题详情
export function getTopicDetail(params = {}) {
  const req = request.create('SingleDiscussionDetailReq', params);
  return request({
    url: '/community/discussion/queryDiscussionDetailById',
    requestBody: req,
    responseType: 'SingleDiscussionDetailRes',
  });
}

// 话题点赞或取消点赞
export function likeTopic(params = {}) {
  const req = request.create('LikeOrCancelReq', params);
  return request({
    url: '/community/like_forward/likeOrCancel',
    requestBody: req,
    responseType: 'CommunityCommonRes',
  });
}

// 话题转发
export function forwardTopic(params = {}) {
  const req = request.create('ForwardReq', params);
  return request({
    url: '/community/like_forward/forward',
    requestBody: req,
    responseType: 'CommunityCommonRes',
  });
}

// 话题评论
export function commentTopic(params = {}) {
  const req = request.create('CommentAddReq', params);
  return request({
    url: '/community/comment/add',
    requestBody: req,
    responseType: 'CommunityCommonRes',
  });
}

// 删除评论
export function deleteComment(params = {}) {
  const req = request.create('RemoveCommentReq', params);
  return request({
    url: '/community/comment/removeCommentById',
    requestBody: req,
    responseType: 'CommunityCommonRes',
  });
}

// 是否阅读社区协议
export function isReadCommunityProtocol() {
  return request({
    url: '/community/read_protocol/is_read',
    responseType: 'ReadProtocolRes',
  });
}
