import { useState } from 'react';
import Head from 'next/head';
import styles from './App.module.scss';
import { Provider } from 'react-redux';
import { useStore } from '@/store';
import { ACCOUNT_TYPE, parseAccountId } from '@/utils/helper';
import { isClient } from '@/utils/core';
import request from '@/utils/request';
import { changeLanguage, languageMap } from '@/utils/i18n';
import {
  setIsInApp,
  getIsInApp,
  setIsSeller,
  setEnv,
  getPlatform,
  setLayer,
  getEnv,
} from '@/utils/config';
import AppPage from '@/components/AppPage/AppPage';
import { isInApp, isInFlutter, callBridge, register } from '@/utils/bridge';
import ETracker from '@easyview/tracker';
import { commonReport } from '@/api/user';
import packageJson from '@/package.json';
import { listenError } from '@/utils/errorLog';
import { getIndustryType } from '@/utils/enum';

let userInfo;
let deviceInfo = {};

(function getUserInfo() {
  if (isClient()) {
    if (isInApp()) {
      setIsInApp(true);
      if (isInFlutter()) {
        let data = navigator.userAgent;
        if (typeof data === 'string') {
          data = JSON.parse(data);
        }
        userInfo = data.userInfo;
        deviceInfo = data.deviceInfo;
      } else {
        userInfo = callBridge('appUserInfo');
        if (typeof userInfo === 'string') {
          userInfo = JSON.parse(userInfo);
        }
        deviceInfo = callBridge('appDeviceInfo') || {};
      }
      setEnv(userInfo?.netEnvType);
      // 上报逻辑
      if (['aws', 'production'].includes(getEnv()) && userInfo.accountId) {
        let industry = 'None';
        const { accountType } = parseAccountId(userInfo.accountId);
        if (accountType === ACCOUNT_TYPE.INDIVIDUAL) {
          industry = 'Personal';
        }
        if (userInfo?.industry) {
          industry = getIndustryType().find(
            (d) => d.value === userInfo?.industry
          )?.desc;
        }
        register('appLayer', (data) => {
          setLayer(data.actionPath);
        });
        dataLayer.push({
          user_name: userInfo.userName,
          user_id: userInfo.accountId,
          app_version: userInfo.appVersion,
          h5_version: packageJson.version,
          institution_name: userInfo.institutionName,
          industry,
          statusBarHeight: userInfo.statusBarHeight,
          env: getEnv(),
        });
      }
    } else {
      setIsInApp(false);
      setEnv(1);
      userInfo = {
        token: localStorage.getItem('token'),
        accountId: localStorage.getItem('accountId'),
        webEntryType: +localStorage.getItem('webEntryType'),
        terminal: +localStorage.getItem('terminal'),
        deviceType: +localStorage.getItem('deviceType'),
        appVersion: localStorage.getItem('appVersion'),
        language: +localStorage.getItem('language'),
        userName: localStorage.getItem('userName'),
        statusBarHeight: localStorage.getItem('statusBarHeight'),
      };
      deviceInfo = {
        webID: packageJson.name,
        webVersion: packageJson.version,
      };
      // dataLayer.push({
      //   user_id: localStorage.getItem('accountId'),
      //   user_name: localStorage.getItem('userName'),
      //   app_version: userInfo.appVersion,
      //   h5_version: packageJson.version,
      // });
    }
    setIsSeller(+userInfo?.webEntryType === 0);
    request.setWebEntryType(userInfo?.webEntryType);
    request.setDeviceType(userInfo?.deviceType);
    request.setTerminal(userInfo?.terminal);
    request.setAppVersion(userInfo?.appVersion);
    request.init();
    request.setToken(userInfo?.token);
    request.setAccountId(userInfo?.accountId);
    request.setLang(userInfo?.language);
    ETracker.init({
      server: '/',
      userid: userInfo?.accountId,
      terminal: getIsInApp() ? 'H5' : 'PC',
      debug: process.env.NODE_ENV === 'development',
      onReport: (_url, data) => {
        const { onReport, ...props } = data;
        commonReport({
          event: props.eventEnName,
          props: {
            ...props,
            ...deviceInfo,
          },
        });
      },
    });
    listenError(userInfo);
  }
  const { institutionId } = parseAccountId(userInfo?.accountId);
  getPlatform(institutionId);
})();

// lang
(function initLang() {
  const lang = userInfo?.language ?? 0;
  changeLanguage(languageMap[lang]);
})();

// (function initEruda() {
//   var script = document.createElement('script');
//   script.src = 'https://unpkg.com/eruda@latest/eruda.js';
//   document.body.appendChild(script);
//   script.onload = function () {
//     eruda.init();
//   };
// })();

function App({ Component, pageProps }) {
  const store = useStore();
  const { accountType } = parseAccountId(userInfo?.accountId);
  store.dispatch({
    type: 'UPDATE_ACCOUNT_TYPE',
    accountType: accountType,
  });

  // if (isInApp()) {
  store.dispatch({
    type: 'UPDATE_LOGIN_USER_INFO',
    loginUserInfo: userInfo,
  });

  // store.dispatch({
  //   type: 'UPDATE_PLATFORM_TYPE',
  //   platformType: platformType,
  // });
  // }
  return (
    <div className={styles.App}>
      <Head>
        <title></title>
        <meta
          name="viewport"
          content="initial-scale=1.0, width=device-width, maximum-scale=1.0, user-scalable=no"
        />
        {/* <script type="text/javascript" src="https://unpkg.com/eruda@latest/eruda.js"></script>
        <script>eruda.init()</script> */}
      </Head>
      <Provider store={store}>
        <AppPage>
          <Component {...pageProps} />
        </AppPage>
      </Provider>
    </div>
  );
}

export default App;
