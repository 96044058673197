import _ from 'lodash';
import CryptoJS from 'crypto-js';
import { getMessage, ResultCode, WSMsgId } from './pb';
import { isClient } from './core';

const instances = {};

// 请求体message
const PBMessageWebSocketRequest = getMessage('WSClientMsg');
// 请求体公共参数
const PBMessageWebSocketRequestHeader = getMessage('WSClientMsgHeader');
// 响应体message
const PBMessageWebSocketResponse = getMessage('WSServerMsg');

export default class RealTime {
  accountId = null;
  isInited = false;
  sequence = 0;

  constructor(opts) {
    const { url } = opts;
    if (url && instances[url]) {
      return instances[url];
    } else {
      this.options = Object.assign(
        {
          url: '',
          heartbeatInterval: 30 * 1000,
          timeout: 10 * 1000,
        },
        opts
      );
      this.callbacks = {};
      this.reqTmp = [];
      this.init();
      if (this.options.url) {
        instances[url] = this;
      }
    }
  }

  init() {
    if (isClient() && !WebSocket) {
      throw new Error('Can not find WebSocket!');
    } else {
      this.send = this.send.bind(this);
      this.handleOpen = this.handleOpen.bind(this);
      this.handleError = this.handleError.bind(this);
      this.handleClose = this.handleClose.bind(this);
      this.handleResponse = this.handleResponse.bind(this);
      this.isInited = true;
    }
  }

  getState() {
    if (this.ws) {
      return this.ws.readyState;
    }
  }

  sub(topic, responseType, cb) {
    this.addSubCallback(topic, responseType, cb);
  }

  unsub(topic, cb) {
    if (!topic || !cb) return;
    const cbs = this.callbacks[topic];
    if (!cbs || cbs.length < 1) return;
    this.callbacks[topic] = cbs.filter((c) => {
      return c[0] !== cb;
    });
  }

  connect(accountId, token) {
    if (this.ws || !this.isInited) return;
    const { url } = this.options;
    if (!url) {
      throw new Error('url can not be null!');
    }
    this.ws = new WebSocket(url);
    this.login(accountId, token);
    this.addEvent();
  }

  addEvent() {
    if (!this.ws) return;
    this.ws.addEventListener('error', this.handleError);
    this.ws.addEventListener('close', this.handleClose);
    this.ws.addEventListener('open', this.handleOpen);
  }

  removeEvent() {
    if (!this.ws) return;
    this.ws.removeEventListener('error', this.handleError);
    this.ws.removeEventListener('close', this.handleClose);
    this.ws.removeEventListener('open', this.handleOpen);
    this.ws.removeEventListener('message', this.handleResponse);
  }

  startHeartbeat() {
    const { heartbeatInterval } = this.options;
    this.heartbeatTimer = setInterval(() => {
      this.send({
        msgId: WSMsgId.WS_MSG_ID_PING, // heart beat
      });
    }, heartbeatInterval);
  }

  reconnect() {
    if (this.ws) {
      this.ws.close();
      this.ws = null;
    }
    this.connect();
  }

  addSubCallback(topic, responseType, cb) {
    this.callbacks[topic] = this.callbacks[topic] || [];
    const hasSub = this.callbacks[topic].find((item) => {
      return item[0] === cb;
    });
    if (hasSub) return;
    this.callbacks[topic].push([cb, responseType]);
  }

  login(accountId, token) {
    accountId = accountId || this.accountId;
    token = token || this.token;
    if (!accountId || !token) return;
    this.accountId = accountId;
    this.token = token;
    const signature = this.signature();
    const requestBody = this.create('WebSocketLogin', signature);
    this.send({
      requestBody,
      msgId: WSMsgId.WS_MSG_ID_LOGIN,
    });
  }

  signature() {
    const timestamp = Date.now();
    const nonce = CryptoJS.lib.WordArray.random(16).toString();
    const signature = CryptoJS.MD5(
      `${this.accountId}${nonce}${timestamp}${this.token}`
    ).toString();
    return {
      signature,
      timestamp,
      nonce,
    };
  }

  send(params) {
    if (this.ws) {
      if (this.getState() !== 1) {
        this.reqTmp.push(params);
        return;
      }
      const { msgId } = params;
      const reqHeader = {
        sequence: ++this.sequence,
        msgId,
        accountId: this.accountId,
      };
      const reqData = this.createReqObject(params, reqHeader);
      this.ws.send(reqData);
    }
  }

  createReqObject(params, header) {
    const { requestBody } = params;
    const reqHeader = PBMessageWebSocketRequestHeader.create(header);
    const reqData = PBMessageWebSocketRequest.create({
      header: reqHeader,
      data: requestBody,
    });
    return PBMessageWebSocketRequest.encode(reqData).finish();
  }

  create(protoName, expansion, params) {
    if (typeof expansion === 'object') {
      params = expansion;
      expansion = '';
    }
    const pbConstruct = getMessage(protoName, expansion);
    const encodeParams = pbConstruct.encode(params).finish();
    return encodeParams;
  }

  handleResponse(event = {}) {
    const data = event.data;
    // 判断response是否是arrayBuffer
    if (data == null || !_.isArrayBuffer(data)) {
      return data;
    }
    try {
      const buf = protobuf.util.newBuffer(data);
      // decode响应体
      const decodedResponse = PBMessageWebSocketResponse.decode(buf);
      this.handleResData(decodedResponse);
    } catch (err) {}
  }

  handleResData(resData = {}) {
    const { header, push } = resData;
    if (header.code !== ResultCode.SUCCESS) return;
    const { msgId } = header;
    if (msgId === WSMsgId.WS_MSG_ID_NOTIFY) {
      if (!push) return;
      const { topic } = push;
      const cbs = this.callbacks[topic] || [];
      cbs.forEach((item) => {
        this.applyCallback(item, push.data);
      });
    }
  }

  applyCallback(cbs, data) {
    const cb = cbs[0];
    if (typeof cb === 'function') {
      const responseType = cbs[1];
      const model = getMessage(responseType);
      if (model) {
        data = model.decode(data);
      }
      cb(data);
    }
  }

  handleOpen() {
    this.ws.binaryType = 'arraybuffer';
    this.startHeartbeat();
    if (this.reqTmp.length) {
      this.reqTmp.forEach((item) => {
        this.send(item);
      });
    }
    this.ws.addEventListener('message', this.handleResponse);
  }

  handleError(error) {
    this.reconnect();
    throw error;
  }

  handleClose() {
    this.ws = null;
    delete instances[this.options.url];
    this.removeEvent();
  }

  destroy() {
    if (this.ws) {
      this.ws.close();
    }
  }
}
